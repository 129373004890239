
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 2.5rem;            /* Footer height */
  border-top: 1px solid #767676;
  margin-top: 1.25rem;
  padding: 1.25rem 0;
  position:fixed;
  bottom: 0;
  left: 0;
  background-color: white;
  .footer-content {
    box-sizing: content-box;
    margin: 0 auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    text-align: center;
    .footer-copyright {
      margin: 10px;
    }
  }
}