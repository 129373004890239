@import '../../style/var';
@import '../../style/reset';

$navigationBackground: #FFFFFF;
$navigationHeight: 60px;
$hoverColor: #DDDDDD;
$textColor: #000000;

.nav {
    background: $navigationBackground;
    height: $navigationHeight;
    li {
        display: inline-block;
        list-style: none;
        height: 60px;
        line-height: 60px;
        padding: 0 40px;
        a {
            display: inline;
        }

        img {
            // display: inline-block;
            // overflow: hidden;
            vertical-align: middle;
            height: 50px;
            // width: 200px;
        }
    }

    li:not(:first-child):hover {
    background: $hoverColor;
    transition: background 1s;
    }

    .nav-link {
        color: $textColor;
        text-decoration: none;
    }
}

@media only screen and (max-width: 768px) {
    .nav {
        background: $navigationBackground;
        height: $navigationHeight;
        li {
            display: inline-block;
            list-style: none;
            height: 60px;
            line-height: 60px;
            padding: 0;
            width: 100px;
            a {
                display: inline;
            }
    
            img {
                // display: inline-block;
                // overflow: hidden;
                vertical-align: middle;
                height: 50px;
                // width: 200px;
            }
        }
    
        li:not(:first-child):hover {
        background: $hoverColor;
        transition: background 1s;
        }
    
        .nav-link {
            color: $textColor;
            text-decoration: none;
        }
    }
}

