@import '../../style/var';
@import '../../style/reset';

.main-page {
  background-color: white;
  .introduce-container {
    position: relative;
    margin: 50px 100px 100px 100px;
    .introduce-block {
      width: 50%;
      display: inline-block;
      top: 0;
      .introduce-title {
        font-size: 30px;
        text-align: left;
        font-weight: bold;
      } 
      .introduce-content {
        margin-top: 20px;
        font-size: 20px;
        font-weight: bold;
        text-align: left;
        line-height: 50px;
      }
      .tag-content {
        .tag {
          display: inline-block;
          border-color: black;
          border-style: solid;
          border-width: 2px;
          border-radius: 10px;
          padding: 5px 10px;
          margin: 10px;
        }
      }
    }
    
    video {
      position: absolute;
      display: inline-block;
      width: 350px;
      height: 200px;
      right: 0;
      border-radius: 20px;
      margin: auto 30px;
    }
  }

  .new-post-brand {
      text-align: center;
      font-size: 30px;
      margin: 20px;
      font-weight: bold;
  }

  .new-post-list {
      text-align: center;
      .new-post-element {
          width: 300px;
          height: 150px;
          border-color: black;
          border-style: solid;
          border-width: 2px;
          border-radius: 10px;
          display: inline-block;
          margin: 30px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          transition: transform .5s;
          .new-post-title {
              font-size: 18px;
              margin: 15px 15px 0 15px;
              text-align: left;
              font-weight: bold;
          }
          .new-post-content {
              font-size: 15px;
              font-weight: bold;
              margin: 15px;
              text-align: left;
              line-height: 22px;
          }
          .new-post-image {
              position: absolute;
              right: 0px;
              bottom: 0px;
              display: inline-block;
              text-align: right;
              width: 100%;
              height: 100%;
              opacity: .5;
          }
      }
      .new-post-element:hover {
        transform: scale(1.1);
      }
  }

}

@media only screen and (max-width: 768px) {
  .main-page {
    background-color: white;
    .introduce-container {
      position: relative;
      margin: 50px 20px auto 20px;
      .introduce-block {
        width: 100%;
        display: inline-block;
        top: 0;
        .introduce-title {
          font-size: 30px;
          text-align: left;
          font-weight: bold;
        } 
        .introduce-content {
          margin-top: 20px;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          line-height: 50px;
        }
        .tag-content {
          .tag {
            display: inline-block;
            border-color: black;
            border-style: solid;
            border-width: 2px;
            border-radius: 10px;
            padding: 5px 10px;
            margin: 10px;
          }
        }
      }
      
      video {
        width: 0;
        height: 0;
      }
    }
  
    .new-post-brand {
        text-align: center;
        font-size: 30px;
        margin: 20px;
        font-weight: bold;
    }
  
    .new-post-list {
        text-align: center;
        .new-post-element {
            width: 300px;
            height: 150px;
            border-color: black;
            border-style: solid;
            border-width: 2px;
            border-radius: 10px;
            display: inline-block;
            margin: 30px;
            position: relative;
            overflow: hidden;
            cursor: pointer;
            transition: transform .5s;
            .new-post-title {
                font-size: 18px;
                margin: 15px 15px 0 15px;
                text-align: left;
                font-weight: bold;
            }
            .new-post-content {
                font-size: 15px;
                font-weight: bold;
                margin: 15px;
                text-align: left;
                line-height: 22px;
            }
            .new-post-image {
                position: absolute;
                right: 0px;
                bottom: 0px;
                display: inline-block;
                text-align: right;
                width: 100%;
                height: 100%;
                opacity: .5;
            }
        }
        .new-post-element:hover {
          transform: scale(1.1);
        }
    }
  
  }
}